<template lang="pug">
.inline-block
  .default-button(
    v-bind="$attrs",
    :class="[size, color, { disabled: disabled }]",
    @click="onClick"
  )
    slot
</template>
<script>
export default {
  name: "basebutton",
  inheritAttrs: false,
  emits: ["clickButton"],
  props: {
    size: {
      type: String,
      default: "normal",
    },
    color: {
      type: String,
      default: "blue",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      if (this.disabled == false) this.$emit("clickButton");
    },
  },
};
</script>
<style lang="scss" scoped>
.default-button {
  @apply cursor-pointer text-center rounded-lg text-white font-medium;

  &.normal {
    @apply py-2 px-5;
  }
  &.lite {
    @apply py-1 px-5 text-xs;
  }

  &.red {
    @apply bg-red-500;
  }

  &.yellow {
    @apply bg-yellow-500;
  }
  &.green {
    @apply bg-green-500;
  }

  &.disabled {
    @apply opacity-30 cursor-default;
  }
}
</style>