<template lang="pug">
h1.text-5xl.font-extralight.mb-6 
  slot
</template>
<script>
export default {
  name: "baseHeading",
};
</script>
<style lang="scss" scoped>
</style>