<template lang="pug">
navbar(v-if="showNavBar")
router-view.py-28
</template>
<script>
import navbar from "@/components/structure/navbar";
export default {
  components: {
    navbar,
  },
  data: () => ({
    showNavBar: true,
  }),
  watch: {
    $route(to) {
      if (to.name == "Login") this.showNavBar = false;
      else this.showNavBar = true;
    },
    userLoggedIn: {
      handler() {
        if (this.userLoggedIn && this.$route.name == "Login") {
          this.$router.push({ name: "Home" });
        } else if (!this.userLoggedIn && this.$route.name != "Login") {
          this.$router.push({ name: "Login" });
        }
      },
      immediate: true,
    },
  },
  computed: {
    userLoggedIn() {
      return this.$store.getters["authStore/stateAuth"];
    },
  },
};
</script>

<style lang="scss">
</style>
