<template lang="pug">
.conichi
  table(ref="tableReference")
</template>
<script>
import { DataTable } from "simple-datatables";
import { onMounted, ref, toRefs, watch } from "vue";

export default {
  name: "baseDataTables",
  props: {
    rows: Object,
    headings: Array,
    options: {
      type: Object,
      default: new Object(),
    },
  },
  setup(props) {
    const { rows, headings, options } = toRefs(props);
    const defaultOptions = {
      sortable: true,
      fixedHeight: true,
      perPage: 5,
      labels: {
        perPage: "{select}",
        noRows: "",
      },
    };

    let datatable;
    const tableReference = ref(null);

    onMounted(() => {
      createDataTable();
      if (rows.value) updateDataTable();
    });

    const createDataTable = async () => {
      datatable = new DataTable(tableReference.value, {
        ...defaultOptions,
        ...options.value,
      });
    };

    const updateDataTable = async () => {
      const rows_data = rows.value.map((v) => Object.values(v));
      datatable.destroy();
      createDataTable();
      datatable.insert({
        headings: headings.value,
        data: rows_data,
      });
    };

    watch(rows, updateDataTable);

    const exportDataTable = () => {
      datatable.export({
        type: "csv",
        filename: "billing",
        download: true,
      });
    };

    return { tableReference, exportDataTable };
  },
};
</script>
<style lang="scss" scoped>
</style>