<template lang="pug">
nav.flex.fixed.w-full.items-center.justify-between.px-6.h-16.bg-gray-800.text-gray-200.z-10.shadow-lg
  .flex.items-center
    .cursor-pointer.mr-2(aria-label="Open Menu", @click="drawer")
      svgIcon(type="mdi", :path="mdiMenu", :size="24")
    h1.font-medium Geomaps Admin.
  transition(
    enter-class="opacity-0",
    enter-active-class="ease-out transition-medium",
    enter-to-class="opacity-100",
    leave-class="opacity-100",
    leave-active-class="ease-out transition-medium",
    leave-to-class="opacity-0"
  )
    .z-10.fixed.inset-0.transition-opacity(
      @keydown.esc="isOpen = false",
      v-show="isOpen"
    )
      .absolute.inset-0.bg-black.opacity-20(@click="isOpen = false")

aside.transform.top-0.left-0.fixed.overflow-hidden.ease-in-out.transition-all.duration-300.z-30.max-w-xs.w-full.h-full.p-4(
  :class="isOpen ? 'translate-x-0' : '-translate-x-full'"
)
  .bg-gray-700.h-full.rounded-md.overflow-hidden.shadow-xl
    h1.my-5.px-4.text-xl.font-medium(@click="isOpen = false") Menu Options
    span.button-drawer(@click="goLink({ name: 'Home' })")
      svgIcon.mr-2(type="mdi", :path="mdiHomeOutline", :size="24")
      span Dashboard
    span.button-drawer(@click="goLink({ name: 'ListUsers' })")
      svgIcon.mr-2(type="mdi", :path="mdiEarth", :size="24")
      span Users
    span.button-drawer(@click="goLink({ name: 'ListPayments' })")
      svgIcon.mr-2(type="mdi", :path="mdiChartBarStacked", :size="24")
      span List Payments
    span.button-drawer(@click="goLink({ name: 'Billing' })")
      svgIcon.mr-2(type="mdi", :path="mdiCashRegister", :size="24")
      span Billing
    span.button-drawer(@click="goLink({ name: 'ChargerbacksControl' })")
      svgIcon.mr-2(type="mdi", :path="mdiSecurityNetwork", :size="24")
      span Chargerback Control
</template>
<script>
import {
  mdiMenu,
  mdiHomeOutline,
  mdiEarth,
  mdiChartBarStacked,
  mdiCashRegister,
  mdiSecurityNetwork,
} from "@mdi/js";
export default {
  name: "navbar",
  data: () => ({
    mdiMenu,
    mdiHomeOutline,
    mdiChartBarStacked,
    mdiCashRegister,
    mdiEarth,
    mdiSecurityNetwork,
    isOpen: false,
  }),
  methods: {
    drawer() {
      this.isOpen = !this.isOpen;
    },

    goLink(link) {
      this.isOpen = false;
      this.$router.push(link);
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(isOpen) {
        if (isOpen) document.body.style.setProperty("overflow", "hidden");
        else document.body.style.removeProperty("overflow");
      },
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.button-drawer {
  @apply flex items-center p-4 cursor-pointer hover:bg-gray-500 hover:text-white;
}
</style>