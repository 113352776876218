<template lang="pug">
.default-card.w-full.max-w-lg.bg-gray-700.rounded-2xl.pb-8
  .font-bold.text-xl.mb-1.border-b.border-gray-600.pb-2.mb-2.text-gray-200 {{ headerTitle }}
  .text-base.border-b.border-gray-600.pb-2.mb-2 {{ contentMessage }}
  .flex.justify-end
    baseButton.bg-green-500(@click="$emit('clickDecline')") NO
    baseButton.bg-red-500.ml-2(@click="$emit('clickConfirm')") YES
</template>
<script>
export default {
  name: "baseModalCard",
  emits: ["clickConfirm", "clickDecline"],
  props: {
    headerTitle: {
      type: String,
      default: "empty",
    },
    contentMessage: {
      type: String,
      default: "empty",
    },
  },
};
</script>
<style lang="scss" scoped>
</style>