<template lang="pug">
teleport(to="body")
  .fixed.bottom-4.w-full.left-0.z-50(v-if="show")
    .flex.justify-center
      .py-2.px-8.rounded-md.text-white(:class="[typeAlert]") {{ message }}
</template>
<script>
export default {
  name: "baseAlert",
  data: () => ({
    typeAlert: "success",
    message: "empty",
    show: false,
  }),

  methods: {
    showAlert(message, type) {
      this.typeAlert = type;
      this.message = message;
      this.show = true;
      setTimeout(() => {
        this.show = false;
        this.show = false;
      }, 4000);
    },
  },
};
</script>
<style lang="scss" scoped>
.success {
  @apply bg-green-500;
}
.error {
  @apply bg-red-500;
}
</style>