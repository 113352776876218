<template lang="pug">
.container-input
  label {{ labelText }}
  .box-input(v-bind:class="{ input_error: error }")
    input.outline-none.w-full.pr-4(
      :type="typeInput",
      :value="modelValue",
      @input="$emit('update:modelValue', $event.target.value)",
      :placeholder="placeholder",
      :disabled="disabled",
      :class="[iconPath ? 'pl-10' : 'pl-4']"
    )
    svg-icon(type="mdi", :size="24", :path="iconPath", v-if="iconPath")
  .message_error(v-show="error") {{ error_message }}
</template>

<script>
export default {
  name: "baseInputText",
  emits: ["update:modelValue"],
  props: {
    typeInput: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: String,
    },
    iconPath: {
      type: String,
      default: null,
    },
    labelText: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    error: {
      type: Boolean,
      default: false,
    },
    error_message: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.container-input {
  @apply flex flex-col;
}
input {
  background: none;
}

label {
  @apply font-normal text-gray-300 mb-0 text-sm pl-2;
}
.box-input {
  @apply py-2 rounded-lg bg-gray-600 flex items-center relative;

  svg {
    @apply text-gray-400 flex-grow ml-2 absolute transition-all;
  }
  input {
    @apply outline-none;
    &:focus {
      & + svg {
        @apply text-blue-500;
      }
    }
  }

  &.input_error {
    @apply bg-red-50;

    input:focus + svg,
    svg {
      @apply text-red-400;
    }
  }
}

.message_error {
  @apply text-xs text-red-500 pl-3 pt-1 -mb-2;
}
</style>
