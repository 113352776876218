//import authRepository from '@/repository/auth.repository';

const token = localStorage.getItem('token_session');

const state = {
  loggedIn: token ? true : false
};

const getters = {
  stateAuth: state => state.loggedIn
};

const actions = {
  async setToken({ commit }, token) {
    console.log(token);
    commit('loggedIn', { token: token });
  }
};

const mutations = {
  loggedIn(state, { token }) {
    localStorage.setItem('token_session', token);
    state.loggedIn = true;
  },
  logout(state) {
    localStorage.removeItem('token_session');
    state.loggedIn = false;
  }
};

export const authStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
