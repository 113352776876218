<template lang="pug">
.select-wrapper
  .box-select(@click="toggleOptions")
    .selected {{ optionNameSelected.name || 'None' }}
    svgIcon.chevron(type="mdi", :path="mdiChevronDown", :size="24")
  .box-options(v-if="showOptions")
    ul
      li(
        v-for="option in options",
        :key="option.id",
        @click="selectOption(option.id)"
      ) {{ option.name }}
</template>
<script>
import { mdiChevronDown } from "@mdi/js";
import { computed, ref, toRefs } from "vue";
export default {
  name: "baseSelect",
  emits: ["setOption"],
  data: () => ({
    mdiChevronDown,
  }),
  props: {
    options: {
      type: Array,
      default: () => [{ name: "Empty", id: 1 }],
    },
    optionSelected: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { options, optionSelected } = toRefs(props);
    const showOptions = ref(false);
    const toggleOptions = () => (showOptions.value = !showOptions.value);

    const selectOption = (optionId) => {
      emit("setOption", optionId);
      toggleOptions();
    };

    const optionNameSelected = computed(() => {
      return options.value.find((o) => o.id == optionSelected.value);
    });

    return {
      showOptions,
      toggleOptions,
      selectOption,
      optionNameSelected,
    };
  },
};
</script>
<style lang="scss" scoped>
.select-wrapper {
  @apply relative;
}
.box-select {
  @apply bg-gray-500 text-white text-center flex items-center justify-center rounded-md relative cursor-pointer py-2;
  .chevron {
    @apply absolute right-2;
  }
}

.box-options {
  @apply absolute bg-gray-700 w-full mt-2 rounded-md overflow-hidden z-50;
  ul {
    li {
      @apply py-2 px-4 cursor-pointer;
      + li {
        @apply border-t border-gray-600;
      }

      &:hover {
        @apply bg-gray-500;
      }
    }
  }
}
</style>